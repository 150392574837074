





















import { Component, Ref } from 'vue-property-decorator';
import message from 'ant-design-vue/lib/message';
import { BaseComponent } from '@common-src/mixins/base-component';
import { CorpDetailEntityModel } from '@common-src/entity-model/corp-entity';
import CorpService from '@common-src/service/corp';
import FormComponent from '@common-src/mixins/form-component';
import { UserStoreModule } from '@common-src/store/modules/user';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class CorpDetailComponent extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<CorpDetailEntityModel>;

    beforeRouteEnter(to, from, next) {
        if (to.params.id) {
            CorpService.retrieve(to.params.id).then(res => {
                next(vm => {
                    vm.jtlForm.initForm(res, ViewModeType.UPDATE);
                });
            });
        } else {
            // const corpId = '3c53cec6-dbc5-41b5-bee6-45b5d3723a5e';
            const corpId = _.get(UserStoreModule.UserInfo, 'corpId');
            if (corpId) {
                next(`/dashboard/corp-detail/${corpId}`);
            } else {
                message.warning('企业不存在，请联系管理员');
            }
        }
    }
    saveClick() {
        return CorpService.saveCorpDetail(this.jtlForm.formModel).then(res => {
            // AppStoreModule.SET_PROJECT_INFO(this.jtlForm.formModel);
            this.showMessage('保存成功！');
        }).catch(err => {
            throw err;
        });
    }
}

